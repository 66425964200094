.coin-container {
    display: flex;
    justify-content: center;
}
.coin-row{
    display: flex;
    flex-direction: row;
    justify-items: start;
    align-content: center;
    height: 80px;
    border-bottom: 1px solid #d7d7d7;
    width: 900px;
}
.coin{
    display: flex;
    align-items: center;
    padding-right: 24px;
    min-width: 300px;
}
.coin h1{
font-size: 16px;
    width: 150px;
}

.coin img{
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.coin-symbol{
    text-transform: uppercase;
}
.coin-data{
    display: flex;
    text-align: center;
    align-items:center;

    justify-content: space-between;
    width: 100%;
}

.coin-price{
    display: flex;
    text-align: center;
    align-items:center;

    justify-content: space-between;
    width: 100%;
}
.coin-volume{
    display: flex;
    text-align: center;
    align-items:center;

    justify-content: space-between;
    width: 100%;


}
.coin-marketcap{
    display: flex;
    text-align: center;
    align-items:center;
    justify-content: space-between;
    width: 100%;}

.coin-percent{
    display: flex;
    text-align: center;
    justify-content: space-between;
    width: 100%;}
.red{
    color: red;
}
.green{
    color: #0d800d;
}









